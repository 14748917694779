import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { appI18nKeyTypes } from '@app/shared/constants/i18n-key-type-map';
import { Move } from '@app/move/interfaces/move';
import { DbUtils, UrlUtils } from '@smooved/core';
import { SvgIllustration, UiContext, UiDirection } from '@smooved/ui';
import { Observable } from 'rxjs';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';
import { MoveUtils } from '@app/move/state/move.utils';
import { EnergyStopSuppliersStatus } from '@app/services/energy-stop/enums/energy-stop-suppliers-status';

@Component({
    selector: 'app-success-container',
    templateUrl: './success.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessContainer implements OnInit {
    public readonly i18nKeys = appI18nKeyTypes;
    public readonly uiDirection = UiDirection;
    public readonly uiContext = UiContext;
    public readonly illustrations = SvgIllustration;

    public isEots: boolean;
    public move$: Observable<Move>;

    private transactionId: string;

    public energyStopStore = inject(EnergyStopStore);

    constructor(
        public readonly moveSandbox: MoveSandbox,
        public readonly navigationSandbox: AppNavigationSandbox,
        private readonly route: ActivatedRoute
    ) {}

    public ngOnInit(): void {
        this.transactionId = UrlUtils.getQueryParam('id');
        if (!!this.transactionId) this.moveSandbox.fetch(this.transactionId);

        this.move$ = this.moveSandbox.move$;
        this.isEots = !!this.route.snapshot.queryParams.eots;

        this.moveSandbox.moveOnce$.subscribe((move) => {
            const { transferee } = MoveUtils.getMovers(move);
            this.energyStopStore.load(DbUtils.getStringId(transferee));
        });
    }

    public openMoveDetail = (): void => {
        void this.navigationSandbox.goToRealEstateDashboard(this.transactionId);
    };
    protected readonly EnergyStopSuppliersStatus = EnergyStopSuppliersStatus;
}
